import * as React from "react"
//import { Link } from "gatsby"

export default function Instagram( {children} ) {
    //check data

    console.log({children}.children)

    if ( {children}.children )
    {
        const ig = {children}.children
        console.log('ig is' + ig )
    
        return ( 
            <div 
                className="gatsby-resp-iframe-wrapper"
                style={{
                    position: "relative",
                    overflow: "hidden",
                    paddingBottom: "100%",
                    marginBottom: "1.075rem"
                }}
                >
                <iframe 
                    src={'https://www.instagram.com/p/'+ ig +'/embed'}
                    style={{
                        position: "absolute"
                    }}
                    frameBorder="0"
                    allowFullScreen="no"
                    scrolling="no"
                    allowtransparency="no"
                    width="100%"
                    height="100%"
                    title="Linked Instagram Post">
                </iframe>
            </div>
        )
    }
    return (
        <div>{children}</div>
    )


        

        
    
 

}

