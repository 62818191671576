import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Helmut from "../components/helmut"

import Instagram from "../components/instagram"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  //replace post.html with post.split.somestuff
  //const postwithlinebreaks = data.markdownRemark.html.replace(new RegExp('\n','g'),'\n<br class="flatbreak">');
  const postwithlinebreaks = data.markdownRemark.html;

  let instagramjsx = "";
  if ( post.frontmatter.ig )
  {
    instagramjsx = <Instagram>{post.frontmatter.ig}</Instagram>;
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmut
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline" className="blog-post-header">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <hr />
        </header>
        <section>
          {instagramjsx}
        </section>
        <section
          dangerouslySetInnerHTML={{ __html: postwithlinebreaks }}
          className="articleBody"
        />
        <hr />
        <footer>
        </footer>
      </article>

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        ig
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
